
export default {
  methods: {
    throwError() {
      console.log("clicked");
      throw new Error("Sentry error");
    }
  },
  head() {
    return {
      title: "Home"
    };
  },
  data: () => ({
    cfstreamid: "53c4570ca55c3a7ec440bdb1bb867e9a",
    fdsQuote: {
      icon: "mdi-school",
      author: "Sean McNamara, FDS Chair",
      title: "FDS partner schools",
      avatar: "",
      text:
        "FDS partner schools listed here support Showcase 2020 in its mission to create a space that allows 2020 drama school graduates further opportunity to showcase themselves to agents, casting directors, producers, directors, artistic directors, theatre companies and industry professionals."
    }
  }),
  mounted() {}
};
