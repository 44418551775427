
export default {
    data () {
      return {
        quotes: [
          {text: 'Unbelievably, the industry had no online showcase – until this year.', author: 'Evening Standard.'},
          {text: 'This platform has changed my life.', author: '2020 Drama School Graduate'},
          {text: 'I will definitely come back to it time and again to keep up to date with the work of emerging graduate performers.', author: 'Emily Slack, Agent at Simon & How'}
        ],
      }
    },
  }
