
export default {
  data() {
    return {
      fdsQuote: {
        icon: "mdi-school",
        author: "Sean McNamara, FDS Chair",
        title: "FDS partner schools",
        avatar: "",
        text:
          "FDS partner schools listed here support Showcase 2020 in its mission to create a space that allows 2020 drama school graduates further opportunity to showcase themselves to agents, casting directors, producers, directors, artistic directors, theatre companies and industry professionals."
      },
      partners_logo: [
        {
          src: "https://azpebrodvq.cloudimg.io/v7/_grad_partners/the-stage_350x350.png",
          url: "https://www.thestage.co.uk/",
          alt: "the stage"
        },
        {
          src: "https://azpebrodvq.cloudimg.io/v7/_grad_partners/the-guardian_350x350.png",
          url: "https://www.theguardian.com/uk",
          alt: "the guardian"
        },
        {
          src: "https://azpebrodvq.cloudimg.io/v7/_grad_partners/eveningstandard_350x350.png",
          url: "https://www.standard.co.uk/",
          alt: "london evening standard"
        },
        {
          src: "https://azpebrodvq.cloudimg.io/v7/_grad_partners/whats-on-stage_350x350.jpg",
          url: "https://www.whatsonstage.com/",
          alt: "whats on stage"
        },
        {
          src: "https://azpebrodvq.cloudimg.io/v7/_grad_partners/backstage_350x350.png",
          url: "https://www.backstage.com/",
          alt: "backstage"
        },
        {
          src: "https://azpebrodvq.cloudimg.io/v7/_grad_partners/at-the-theatre_350x350.png",
          url: "https://www.atthetheatre.co.uk/",
          alt: "at the theatre"
        },
        {
          src: "https://azpebrodvq.cloudimg.io/v7/_grad_partners/theatreweekly_350x350.png",
          url: "https://theatreweekly.com/",
          alt: "theatre weekly"
        },
        {
          src: "https://azpebrodvq.cloudimg.io/v7/_grad_partners/official-london-theatre_350x350.png",
          url: "https://officiallondontheatre.com/",
          alt: "official london theatre"
        },
        {
          src: "https://azpebrodvq.cloudimg.io/v7/_grad_partners/broadway-world_350x350.png",
          url: "https://www.broadwayworld.com/",
          alt: "broadway world"
        },
        {
          src: "https://azpebrodvq.cloudimg.io/v7/_grad_partners/arte_350x350.png",
          url: "https://www.arte.tv/fr/videos/103030-000-A/grande-bretagne-jeunes-comediens-a-l-ecole-de-la-debrouille/",
          alt: "arte.tv"
        }
      ]
    };
  }
};
